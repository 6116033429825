/* HOST ----------------------------------- */
#auction-container {
	max-height: 100%;
	flex-grow: 1;
	padding: var(--gap-o);
	padding-left: calc(var(--gap-o) + var(--outer-border));
	overflow-y: auto;
	position: relative;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: var(--gap-o);
	background-color: var(--clr-surface);
	border-radius: var(--sp-2);
	box-shadow: var(--ev-neu-outer);
}

@media screen and (max-width: 2700px) and (min-width: 1921px) {
	#auction-container {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (max-width: 1920px) and (min-height: 1080px) {
	#auction-container {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (max-width: 1680px) and (min-height: 1080px) {
	#auction-container {
		grid-template-columns: repeat(3, 1fr) !important;
	}
}

@media screen and (max-width: 1600px) {
	#auction-container {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (max-width: 1366px) {
	#auction-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 1280px) and (min-height: 1080px) {
	#auction-container {
		grid-template-columns: repeat(2, 1fr) !important;
	}
}

@media screen and (max-width: 1120px) {
	#auction-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 960px) {
	#auction-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 760px) {
	#auction-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 520px) {
	#auction-container {
		grid-template-columns: repeat(1, 1fr) !important;
	}
}

/* PLACEHOLDER ---------------------------- */
#placeholder-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	background-color: var(--clr-surface);
	z-index: 8;
	animation-name: placeholder;
	animation-duration: 400ms;
	animation-delay: 2000ms;
	animation-iteration-count: once;
	animation-fill-mode: forwards;
}

@keyframes placeholder {
	0% {
		opacity: 1;
		z-index: 8;
	}
	99% {
		opacity: 0;
		z-index: 8;
	}
	100% {
		opacity: 0;
		display: none !important;
		z-index: -1;
	}
}

#placeholder-overlay::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: url('/public/u2b.webp');
	background-position: center;
	background-size: 12.5%;
	background-repeat: no-repeat;
	mix-blend-mode: luminosity;
	animation-name: u2b-pulse;
	animation-duration: 400ms;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
}

@keyframes u2b-pulse {
	from {
		transform: scale(1) rotate(-2deg);
		filter: blur(4px);
	}
	to {
		transform: scale(1.5) rotate(2deg);
		filter: blur(0);
	}
}

/**
 * Do not edit directly
 * Generated on Wed, 21 Jun 2023 06:25:42 GMT
 */

:root {
  --spdb-12: 16384px;
  --spdb-11: 8192px;
  --spdb-10: 4096px;
  --spdb-9: 2048px;
  --spdb-8: 1024px;
  --spdb-7: 512px;
  --spdb-6: 256px;
  --spdb-5: 128px;
  --spdb-4: 64px;
  --spdb-3: 32px;
  --spdb-2: 16px;
  --spdb-1: 8px;
  --sp-large-01: 6px;
  --sp-large-12: 144px;
  --sp-large-11: 132px;
  --sp-large-10: 120px;
  --sp-large-9: 108px;
  --sp-large-8: 96px;
  --sp-large-7: 84px;
  --sp-large-6: 72px;
  --sp-large-5: 60px;
  --sp-large-4: 48px;
  --sp-large-3: 36px;
  --sp-large-2: 24px;
  --sp-large-1: 12px;
  --sp-small-01: 4px;
  --sp-small-12: 74px;
  --sp-small-11: 68px;
  --sp-small-10: 62px;
  --sp-small-9: 56px;
  --sp-small-8: 50px;
  --sp-small-7: 44px;
  --sp-small-6: 38px;
  --sp-small-5: 32px;
  --sp-small-4: 26px;
  --sp-small-3: 20px;
  --sp-small-2: 14px;
  --sp-small-1: 8px;
  --sp-01: 4px;
  --sp-00: 2px;
  --sp-12: 96px;
  --sp-11: 88px;
  --sp-10: 80px;
  --sp-9: 72px;
  --sp-8: 64px;
  --sp-7: 56px;
  --sp-6: 48px;
  --sp-5: 40px;
  --sp-4: 32px;
  --sp-3: 24px;
  --sp-2: 16px;
  --sp-1: 8px;
  --clr-ev-occlusion-accent-tertiary: rgba(80, 215, 59, 0.2);
  --clr-ev-highlight-accent-tertiary: rgba(124, 255, 96, 0.9);
  --clr-ev-cast-accent-tertiary: rgba(80, 215, 59, 0.9);
  --clr-ev-occlusion-accent-secondary: rgba(204, 181, 55, 0.2);
  --clr-ev-highlight-s-accent-secondary: rgba(255, 251, 77, 0.3);
  --clr-ev-highlight-accent-secondary: rgba(255, 251, 77, 0.9);
  --clr-ev-cast-s-accent-secondary: rgba(204, 181, 55, 0.5);
  --clr-ev-cast-accent-secondary: rgba(204, 181, 55, 0.9);
  --clr-ev-occlusion-s-secondary-surface: rgba(39, 97, 71, 0.2);
  --clr-ev-occlusion-m-secondary-surface: rgba(39, 97, 71, 0.5);
  --clr-ev-occlusion-secondary-surface: rgba(39, 97, 71, 0.9);
  --clr-ev-highlight-s-secondary-surface: rgba(63, 157, 131, 0.3);
  --clr-ev-highlight-m-secondary-surface: rgba(15, 114, 82, 0.9);
  --clr-ev-highlight-secondary-surface: rgba(63, 157, 131, 0.9);
  --clr-ev-cast-x-secondary-surface: rgba(11, 82, 60, 0.9);
  --clr-ev-cast-secondary-surface: rgba(39, 97, 71, 0.9);
  --clr-ev-occlusion-bg: rgba(195, 204, 210, 0.2);
  --clr-ev-highlight-bg: rgba(229, 240, 245, 0.9);
  --clr-ev-cast-bg: rgba(195, 204, 210, 0.9);
  --clr-ev-occlusion-s: rgba(217, 221, 224, 0.2);
  --clr-ev-occlusion-m: rgba(217, 221, 224, 0.5);
  --clr-ev-occlusion: rgba(217, 221, 224, 0.9);
  --clr-ev-highlight-x: rgba(255, 255, 255, 0.9);
  --clr-ev-highlight: rgba(255, 255, 255, 0.3);
  --clr-ev-cast-x: rgba(175, 192, 207, 0.9);
  --clr-ev-cast: rgba(216, 224, 231, 0.9);
  --clr-ink-secondary: #688292;
  --clr-ink-on-heavy: #ffffff;
  --clr-ink: #6E6974;
  --clr-bg: #D4DEE4;
  --clr-surface-secondary-gr: linear-gradient(to bottom right, #0C5D43,#0E674B);
  --clr-surface-secondary: #0D5E44;
  --clr-surface: #ECF0F3;
  --clr-accent-supplementary-d: #59EF41;
  --clr-accent-supplementary-c: #F3D842;
  --clr-accent-supplementary-b: fuchsia;
  --clr-accent-supplementary-a: #00A3FF;
  --clr-accent-tertiary-heavy: #095F59;
  --clr-accent-tertiary: #59EF41;
  --clr-accent-secondary-heavy-x: #6A5B05;
  --clr-accent-secondary-heavy: #8A7606;
  --clr-accent-secondary: #F3D842;
  --clr-accent-heavy: #E16AB6;
  --clr-accent-light: #FFDDF3;
  --clr-accent: #F198D1;
  --ev-neu-1-bg: 3px 3px 8px var(--clr-ev-cast-bg), -3px -3px 6px var(--clr-ev-highlight-bg), 3px -3px 6px var(--clr-ev-occlusion-bg), -3px 3px 6px var(--clr-ev-occlusion-bg);
  --ev-neu-1-accent-tertiary: 4px 4px 10px var(--clr-ev-cast-accent-tertiary), -4px -4px 8px var(--clr-ev-highlight-accent-tertiary), 4px -4px 8px var(--clr-ev-occlusion-accent-tertiary), -4px 4px 8px var(--clr-ev-occlusion-accent-tertiary);
  --ev-neu-1-hover-accent-secondary: 6px 6px 16px var(--clr-ev-cast-accent-secondary), -6px -6px 12px var(--clr-ev-highlight-accent-secondary), 6px -6px 12px var(--clr-ev-occlusion-accent-secondary), -6px 6px 12px var(--clr-ev-occlusion-accent-secondary), inset -2px -2px 4px var(--clr-ev-cast-s-accent-secondary), 2px 2px 4px var(--clr-ev-highlight-s-accent-secondary);
  --ev-neu-1-accent-secondary: 3px 3px 8px var(--clr-ev-cast-accent-secondary), -3px -3px 6px var(--clr-ev-highlight-accent-secondary), 3px -3px 6px var(--clr-ev-occlusion-accent-secondary), -3px 3px 6px var(--clr-ev-occlusion-accent-secondary), inset -1px -1px 2px var(--clr-ev-cast-s-accent-secondary), 1px 1px 2px var(--clr-ev-highlight-s-accent-secondary);
  --ev-neu-1-inset-surface-secondary: inset 4px 4px 10px var(--clr-ev-cast-x-secondary-surface), -4px -4px 8px var(--clr-ev-highlight-m-secondary-surface), 4px -4px 8px var(--clr-ev-occlusion-s-secondary-surface), -4px 4px 8px var(--clr-ev-occlusion-s-secondary-surface);
  --ev-neu-1-hover-surface-secondary: 6px 6px 16px var(--clr-ev-cast-x-secondary-surface), -6px -6px 12px var(--clr-ev-highlight-m-secondary-surface), 6px -6px 12px var(--clr-ev-occlusion-s-secondary-surface), -6px 6px 12px var(--clr-ev-occlusion-s-secondary-surface);
  --ev-neu-1-surface-secondary: 3px 3px 8px var(--clr-ev-cast-x-secondary-surface), -3px -3px 6px var(--clr-ev-highlight-m-secondary-surface), 3px -3px 6px var(--clr-ev-occlusion-s-secondary-surface), -3px 3px 6px var(--clr-ev-occlusion-s-secondary-surface);
  --ev-neu-inner-action: 1px 1px 3px var(--clr-ev-occlusion), -1px -1px 2px var(--clr-ev-highlight-x), 1px -1px 2px var(--clr-ev-occlusion-s), -1px 1px 2px var(--clr-ev-occlusion-s);
  --ev-neu-1-inset: inset 1px 1px 3px var(--clr-ev-occlusion), inset -1px -1px 2px var(--clr-ev-highlight-x), inset 1px -1px 2px var(--clr-ev-occlusion-s), inset -1px 1px 2px var(--clr-ev-occlusion-s), -1px -1px 2px var(--clr-ev-occlusion-m), 1px 1px 2px var(--clr-ev-highlight);
  --ev-neu-1-hover: 12px 12px 30px var(--clr-ev-cast), -12px -12px 24px var(--clr-ev-highlight-x), 12px -12px 24px var(--clr-ev-occlusion), -12px 12px 24px var(--clr-ev-occlusion), inset -2px -2px 4px var(--clr-ev-occlusion), inset 2px 2px 4px var(--clr-ev-highlight) ;
  --ev-neu-1: 6px 6px 15px var(--clr-ev-cast), -6px -6px 12px var(--clr-ev-highlight-x), 6px -6px 12px var(--clr-ev-occlusion), -6px 6px 12px var(--clr-ev-occlusion), inset -1px -1px 2px var(--clr-ev-occlusion), inset 1px 1px 2px var(--clr-ev-highlight) ;
  --ev-neu-outer-surface-secondary: 1px 1px 3px var(--clr-ev-cast-secondary-surface), -1px -1px 2px var(--clr-ev-highlight-secondary-surface), 1px -1px 2px var(--clr-ev-occlusion-secondary-surface), -1px 1px 2px var(--clr-ev-occlusion-secondary-surface), inset -1px -1px var(--clr-ev-occlusion-m-secondary-surface), inset 1px 1px 2px var(--clr-ev-highlight-s-secondary-surface);
  --ev-neu-outer: 1px 1px 3px var(--clr-ev-cast-x), -1px -1px 3px var(--clr-ev-highlight), 1px -1px 2px var(--clr-ev-occlusion), -1px 1px 2px var(--clr-ev-occlusion), inset -1px -1px 2px var(--clr-ev-occlusion), inset 1px 1px 2px var(--clr-ev-highlight);
}

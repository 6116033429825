/* HOST ----------------------------------- */
.auction-card {
	padding: var(--gap-ii);
	position: relative;
	grid-column: span 1;
	display: flex;
	flex-direction: column;
	border-radius: var(--sp-2);
	box-shadow: var(--ev-neu-1);
	transition: 150ms;
	cursor: pointer;
	min-height: 300px;
}

@media screen and (max-width: 1366px) {
	.auction-card {
		min-height: 420px;
	}
}

@media screen and (max-width: 1200px) {
	.auction-card {
		min-height: 360px;
	}
}

@media screen and (max-width: 520px) {
	.auction-card {
		min-height: 600px;
	}
}

.auction-card:hover {
	box-shadow: var(--ev-neu-1-hover);
}

/* THUMBNAIL ------------------------------ */
.thumbnail-container {
	position: relative;
	overflow: hidden;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	border-radius: var(--sp-1);
	box-shadow: var(--ev-neu-1-inset);
	transition: 300ms;
}

.thumbnail {
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: var(--sp-1);
	background-size: cover;
	background-position: center;
	transition: 300ms;
}

.auction-card:hover .thumbnail {
	transform: scale(1.12);
}

.auction-card[data-is-vip='true'] .thumbnail::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--clr-accent-secondary-heavy);
	opacity: 0;
	transition: 300ms;
}

.auction-card:hover .thumbnail::after {
	opacity: 0.5;
}

/* VIP BADGE ------------------------------ */
.vip-badge-container {
	width: var(--sp-6);
	height: var(--sp-6);
	padding: var(--gap-ii);
	position: absolute;
	top: var(--gap-ii);
	left: var(--gap-ii);
	background-color: var(--clr-accent-secondary);
	border-radius: 50%;
	outline: 1px solid transparent;
	transition: 500ms;
	transition-timing-function: cubic-bezier(0.68,-0.55,0.27,1.55);
	z-index: 2;
}

[data-is-vip='false'] .vip-badge-container {
	display: none;
}

.auction-card:hover .vip-badge-container {
	top: calc(50% - (var(--sp-5) / 2));
	left: calc(50% - (var(--sp-5) / 2));
	transform: scale(1.5);
	outline: 1px solid var(--clr-accent-secondary);
	animation-name: outline-pulse;
	animation-duration: 1200ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
}

@keyframes outline-pulse {
	from {
		outline-offset: 0;
	}
	to {
		outline-offset: 6vw;
		outline: 1px solid transparent;
	}
}

.vip-badge {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	outline: 1px solid transparent;
	box-shadow: var(--ev-neu-1-accent-secondary);
}

.auction-card:hover .vip-badge {
	outline: 1px solid var(--clr-accent-secondary);
	animation-name: outline-pulse;
	animation-duration: 1200ms;
	animation-delay: 150ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-out;
}

.vip-badge > svg {
	width: var(--sp-3);
	height: var(--sp-3);
	fill: var(--clr-accent-secondary-heavy-x);
}

.auction-card:hover svg {
	animation-name: svg-pulse;
	animation-duration: 600ms;
	animation-direction: alternate-reverse;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in;
}

@keyframes svg-pulse {
	from {
		transform: scale(1) rotate(-4deg);
	}
	to {
		transform: scale(0.75) rotate(4deg);
	}
}

.vip-string {
	position: absolute;
	font-family: var(--font-pixel);
	font-weight: bold;
	font-size: 1.25rem;
	color: var(--clr-accent-secondary);
	filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.5));
	transition-timing-function: cubic-bezier(0.68,-0.55,0.27,1.55);
	opacity: 0;
	z-index: 2;
}

[data-is-vip='false'] .vip-string {
	display: none;
}

.auction-card:hover .vip-string {
	opacity: 1;
}

.vip-string:nth-of-type(1) {
	top: calc(var(--gap-ii) * -3);
	transition: 150ms;
	transition-delay: 200ms;
}

.auction-card:hover .vip-string:nth-of-type(1) {
	top: var(--gap-ii);
}

.vip-string:nth-of-type(2) {
	bottom: calc(var(--gap-ii) * -1);
	transition: 150ms;
	transition-delay: 400ms;
}


.auction-card:hover .vip-string:nth-of-type(2) {
	bottom: var(--gap-ii);
}


/* DETAILS -------------------------------- */
.details {
	max-width: 100%;
	height: fit-content;
	padding: var(--sp-1) 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.details > span {
	font-size: 1.125rem;
	font-family: var(--font-mono);
	color: var(--clr-ink);
}

/* ACTION --------------------------------- */
.action {
	height: var(--sp-4);
	max-height: var(--sp-4);
	border-style: none;
	border: 1px solid transparent;
	border-radius: var(--sp-1);
	background-color: var(--clr-surface);
	font-family: var(--font-pixel);
	color: var(--clr-accent-heavy);
	box-shadow: var(--ev-neu-inner-action);
	cursor: pointer;
	transition: 150ms;
}

@media screen and (min-width: 1921px) {
	.action {
		height: var(--sp-7);
	}
}

.action:hover {
	border: 1px solid var(--clr-accent);
}

/* WINNING BADGE --------------------------- */
.user-winning-badge {
	position: absolute;
	top: 0;
	right: var(--gap-i);
}

[data-is-winning='false'] .user-winning-badge {
	display: none;
}

/* WINNING STRING ------------------------- */
.user-winning-string-container {
	height: var(--sp-3);
	padding-bottom: 2px;
	opacity: 0;
	position: absolute;
	top: 0;
	right: var(--sp-1);
	left: var(--sp-1);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--sp-3);
	background-color: var(--clr-accent-secondary);
	z-index: 6;
	transition: 300ms;
}

[data-is-winning='true']:hover .user-winning-string-container {
	opacity: 1;
	top: calc(var(--gap-i) * -1.25);
}

[data-is-winning='true']:hover .user-winning-string-container {
	animation-name: winning-pulse;
	animation-duration: 1200ms;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	animation-timing-function: linear;
}

@keyframes winning-pulse {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0.875);
	}
}

.user-winning-string-container > span {
	color: var(--clr-accent-secondary-heavy);
	filter: drop-shadow(0 0 2px var(--clr-accent-secondary));
}

/* HOST ----------------------------------- */
#side-panel {
	--panel-w: 400px;
	width: var(--panel-w);
	padding: var(--gap-i);
	overflow-x: hidden;
	overflow-y: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: var(--gap-ii);
	background-color: var(--clr-surface-secondary);
	background-image: url('/public/cut-mat.webp');
	background-size: auto 102.5%;
	background-position: 25% center;
	border-radius: var(--sp-2) 0 0 var(--sp-2);
	box-shadow: var(--ev-neu-outer-surface-secondary);
	transition: 250ms;
	z-index: 6;
}

@media screen and (min-width: 1920px) {
	#side-panel {
		--panel-w: 600px;
	}
}

@media screen and (max-width: 960px) {
	#side-panel {
		position: fixed;
		top: var(--gap-i);
		right: 0;
		transform: translateX(var(--panel-w));
		/*  */
		/* This property needs to be toggled on via prop/data attribute etc for the panel reveal mechanism */
		/*  */
		bottom: var(--gap-i);
	}

	#sidepanel[data-is-visible='true'] {
		right: 400px;
	}
}

/* HIDE BUTTON----------------------------- */
#hide-button {
	height: var(--sp-5);
	border: 1px solid var(--clr-accent-tertiary-heavy);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--sp-12);
	background-color: var(--clr-surface-secondary);
	box-shadow: var(--ev-neu-1-surface-secondary);
	cursor: pointer;
	transition: 300ms;
}

@media screen and (min-width: 961px) {
	#hide-button {
		display: none;
	}
}

/* @media screen and (min-height: 861px) {
	#hide-button {
		display: none;
	}
} */

#hide-button:hover {
	background-color: var(--clr-accent-secondary);
}

#hide-button span {
	font-family: var(--font-pixel);
	font-size: 0.875rem;
	color: var(--clr-accent-secondary);
	transition: 300ms;
}

#hide-button:hover span {
	color: var(--clr-accent-secondary-heavy);
	transform: scale(1.12);
}

/* DETAILS -------------------------------- */
#focus-token-details {
	padding: var(--gap-i);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: var(--gap-i);
	background-image: var(--clr-surface-secondary-gr);
	border: 1px solid var(--clr-accent-secondary);
	border-radius: var(--sp-3);
}

#focus-token-title {
	min-height: var(--sp-5);
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: var(--ev-neu-1-inset-surface-secondary);
	border-radius: var(--sp-2);
}

#focus-token-title span {
	font-family: var(--font-pixel);
	font-size: 1.125rem;
	color: var(--clr-accent-secondary);
}

/* Auction details */
#focus-token-auction-details-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: var(--gap-ii);
}

.focus-token-auction-details-item {
	height: var(--sp-4);
	padding: 0 var(--gap-i);
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: var(--ev-neu-1-inset-surface-secondary);
	border-radius: var(--sp-1);
}

.focus-token-auction-details-item span {
	font-family: var(--font-pixel);
	font-size: 0.875rem;
}

.focus-token-auction-details-item span:first-child {
	color: var(--clr-accent-secondary);
}

.focus-token-auction-details-item span:last-child {
	color: var(--clr-ink-on-heavy);
}

/* IMAGE ---------------------------------- */
#focus-token-image-container {
	width: 100%;
	height: 100%;
	min-height: var(--sp-12);
	flex-grow: 1;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--sp-2);
}

#focus-token-image {
	height: 100%;
	position: absolute;
	border-radius: var(--sp-2);
}

/* VIP BADGE ------------------------------ */
.vip-badge-container {
	width: 100%;
	height: var(--sp-6);
	padding: var(--gap-ii);
	position: absolute;
	bottom: var(--gap-ii);
	/* left: 0; */
	background-color: var(--clr-accent-secondary);
	border-radius: var(--sp-6);
	z-index: 2;
}

[data-is-vip='false'] .vip-badge-container {
	display: none;
}

.vip-badge {
	width: 100%;
	height: 100%;
	padding: 0 var(--gap-ii);
	display: flex;
	align-items: center;
	border-radius: var(--sp-6);
	outline: 1px solid transparent;
	box-shadow: var(--ev-neu-1-accent-secondary);
}

.vip-badge > svg {
	width: var(--sp-3);
	height: var(--sp-3);
	fill: var(--clr-accent-secondary-heavy-x);
}

.vip-string {
	width: 100%;
	padding: 0 var(--gap-ii);
	display: flex; 
	justify-content: center;
	font-family: var(--font-mono);
}

